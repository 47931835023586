import { reactive,toRefs,onMounted,watch,getCurrentInstance,nextTick,defineComponent} from 'vue';
import ContentUtil,{IContentDataObj} from "@/components/layout/ts/content/contentUtil";
import {useRoute} from "vue-router";
import Portal from '../../portal/Portal.vue';
export default defineComponent({
    name: "Content",
    setup(){
        const {proxy} = getCurrentInstance() as any;
        const route = useRoute()
        let dataObj:IContentDataObj=reactive<IContentDataObj>({
            refMap:new Map(),
            utilInst:{}as any,
            compRef:null,//路由组件ref（方便在其它路由那里访问ContentArea组件的时候，可以访问到里面具体包含的路由组件，所以不能删除）
            outerLink:'',//外部链接地址
            isRouterAlive: true,//更改状态，使路由重新导入，实现刷新效果
            //二级路由动画效果
            animationName:!localStorage.getItem('routeAnimation')?'fade-transform':localStorage.getItem('routeAnimation')as string
        })

        onMounted(()=>{
            dataObj.utilInst=new ContentUtil(proxy,dataObj);
            nextTick(async ()=>{
                //之前进入ContentArea就会自动触发路由的变化，进而在watch事件那里处理外部链接就可以了，但是该版本的router在进入ContentArea的时候，
                //并不会触发路由变化事件，于是就不会触发watch，就不能显示外部链接了，于是需要在onMounted里面单独调用以下。
                //比如你在layer那里点击报表设计，跳转到该页来，如果不在onMounted这里调用dealOuterLink，那么页面什么都不会显示。
                dataObj.utilInst.dealOuterLink(route.meta);
            })
        });
        //监听当前路由地址变化(如果路由地址为链接，则用iframe，否则用router-view)
        watch(() => route.path,() => {
            dataObj.utilInst.dealOuterLink(route.meta);
        })

        //重新加载路由。每个tab上面的刷新按钮点击的时候，其实调用的就是该方法，
        const reload=()=> {
            dataObj.utilInst.reloadRoute();
        }
        return{
            ...toRefs(dataObj),reload,route
        }
    },
    components: {
        Portal
    }
});