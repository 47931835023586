import BaseBean from "@/utils/BaseBean";
import {nextTick} from "vue";
import {useRoute} from "vue-router";

export interface IContentDataObj {
    refMap:Map<string,any>
    utilInst:any
    compRef:any//路由组件ref
    outerLink:string//外部链接地址
    isRouterAlive: boolean//更改状态，使路由重新导入，实现刷新效果
    //二级路由动画效果
    animationName:string
}
export default class ContentUtil extends BaseBean{
    public dataObj:IContentDataObj;
    constructor(proxy:any,dataObj:IContentDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    //处理外部链接
    public dealOuterLink(meta:any):void{
        if(meta.page && meta.page.startsWith("http")){//是链接，则放到iframe中加载
            if(meta.type==1){//type=1，表示积木报表，需要往url后面追加token
                const userToken = sessionStorage.getItem(this.utils.Const.jfAccessToken);
                let pageUrl=meta.page+'?token='+userToken;
                this.dataObj.outerLink=pageUrl;
            }else{
                this.dataObj.outerLink=meta.page as string;
            }
        }else{
            this.dataObj.outerLink='';
        }
    }
    //重载路由，点击tabs中的刷新图标调用contentArea.vue中的该方法
    public reloadRoute():void{
        this.dataObj.isRouterAlive = false;
        nextTick(()=>{
            this.dataObj.isRouterAlive = true;
        })
    }
}